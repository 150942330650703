import React from "react"
import Layout from "components/Layout"

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>
      You just hit a route that doesn&#39;t exist... just what are you trying to
      pull???
    </p>
  </Layout>
)

export default NotFoundPage
